<template>
  <div>
    <ModalAddReason @refetch-data="getStatus" :showToast="showToast" :codes="codes" :nameReasons=nameReasons />
    <ModalEditReason :refuseStatusId="refuseStatusId" @refetch-data="getStatus" :showToast="showToast" :codes="codes"
      :nameReasons="nameReasons" />
    <ModalDeleteReason :refuseStatusId="refuseStatusId" :showToast="showToast" @refetch-data="getStatus"
      :allowEdit="allowEdit" />
    <b-card id="card-refuse-status">
      <div>
        <div class="d-flex align-items-center justify-content-start c-header">
          <b-form-group class="w-100">
            <div
              class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center  justify-content-sm-between">
              <span class="c-title">{{ $t('RefuseStatus.Title') }}</span>
              <b-button class="btn-new-reason" v-b-modal.modal-add-reason>
                <span class="text-nowrap">{{ $t('RefuseStatus.Add') }}</span>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <div class="mt-1 table-container">
          <table-default :rows="rows" :fields="fields" :stickyHeader="false" :striped="true" :sizeSearch="12"
            id="table-reasons">
            <template v-slot:cell(action)="data">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="d-action-reasons">
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="d-color align-middle mr-25" />
                  </template>
                  <b-dropdown-item v-b-modal.modal-edit-reason @click="setRefuseStatusId(data.item.id)">
                    <EditIcon />
                    <span class="d-itens m-1">
                      {{ $t('RefuseStatus.Edit') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal.modal-delete-reason
                    @click="setRefuseStatusId(data.item.id, data.item.has_operation_progress)">
                    <DeleteIcon />
                    <span class="d-itens ml-1">
                      {{ $t('RefuseStatus.Delete') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
            <template v-slot:cell(description)="data">
              <span :id="`tooltip-reason-description-${data.index}`">
                {{ data.item.description }}
              </span>
              <b-tooltip custom-class="tooltip-refuse-status" triggers="hover blur"
                :target="`tooltip-reason-description-${data.index}`" positioning="top" placement="top"
                boundary-padding="0">
                <div class="info-tooltip-refuse-status">
                  {{ data.item.description }}
                </div>
              </b-tooltip>
            </template>
          </table-default>
        </div>
        <div class="demo-spacing-0">
          <b-alert variant="primary" :show="(flagShowOverlay == false && rows.length == 0) ? true : false">
            <div class="alert-body">
              <span>Nenhum resultado encontrado.</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BCard, BTooltip, VBToggle, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BTable from '@core/components/b-table/BTable.vue'
import TableDefault from '@core/components/table-default/TableDefault.vue'
import DeleteIcon from '@/assets/images/pages/trash-icon-grey.svg'
import EditIcon from '@/assets/images/pages/edit-icon-grey.svg'
import ModalAddReason from '@/@core/components/b-modal/ModalAddReason.vue'
import ModalEditReason from '@/@core/components/b-modal/ModalEditReason.vue'
import ModalDeleteReason from '@/@core/components/b-modal/ModalDeleteReason.vue'
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    ToastificationContent,
    BButton,
    BTooltip,
    BTable,
    TableDefault,
    EditIcon,
    DeleteIcon,
    ModalAddReason,
    ModalEditReason,
    ModalDeleteReason
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      currentSite: this.$cookies.get('userInfo').currentSite,
      pageLength: 10,
      dir: false,
      refuseStatusId: '',
      allowEdit: false,
      fields: [
        {
          label: 'RefuseStatus.Action',
          key: 'action',
          class: 'text-center pl-1 pr-1'
        },
        {
          label: 'RefuseStatus.Code',
          key: 'code',
          sortable: true,
          class: 'pl-1 pr-1'
        },
        {
          label: 'RefuseStatus.Name',
          key: 'name',
          sortable: true,
          class: 'pl-1 pr-1'
        },
        {
          label: 'RefuseStatus.Description',
          key: 'description',
          sortable: true,
          class: 'pl-1'
        },
      ],
      rows: [],
      searchTerm: '',
      codes: [],
      nameReasons: []
    }
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    setRefuseStatusId(id, allowEdit = null) {
      this.refuseStatusId = id;

      if (allowEdit != null) {
        this.allowEdit = allowEdit == 0 ? true : false;
      }
    },
    getStatus() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/refuse-status?site_guid=${this.currentSite}`)
        .then(res => {
          this.rows = res.data.response;
          this.codes = this.rows.map(item => item.code);
          this.nameReasons = this.rows.map(item => item.name);
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  },
  computed: {
    ...mapGetters('app', [
      'flagShowOverlay'
    ]),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getStatus()
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';

.table-container {
  overflow-x: hidden;
  margin-top: 0px !important;
  padding-top: 8px !important;
}

.card-body {
  padding: 16px;
}

.tooltip-refuse-status {
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;

  .tooltip-inner {
    padding: 0;
    max-width: 100%;
    min-width: 1%;
    border-radius: 6px;
    overflow: hidden;

    .info-tooltip-refuse-status {
      max-width: 339px;
      padding: 8px 8px;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      letter-spacing: 0.16px;
    }
  }

  .arrow {
    &::before {
      border-top-color: #323232;
      border-bottom-color: #323232;
    }
  }
}


.overlay-mensage {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}


@media (max-width:480px) {
  .tooltip-refuse-status {
    .tooltip-inner {
      .info-tooltip-refuse-status {
        max-width: 288px !important;
      }
    }
  }

  #card-refuse-status {
    .c-header {
      .btn-new-reason {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

#card-refuse-status {

  .c-header {
    .c-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4C4541 !important;
    }

    .btn-new-reason {
      background: #974900 !important;
      border: none;
      padding: 4px 14px !important;

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.0125em;
      }
    }
  }

  #table-reasons {

    table {
      min-height: 120px;

      td {
        height: 38px !important;
        letter-spacing: 0;
        border: 1px solid #E0E0E0 !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
      }

      tbody tr td,
      thead tr th {
        outline: none !important;
        border-left: none !important;
        border-right: none !important;
      }

      tbody tr td:nth-child(1),
      thead tr th:nth-child(1) {
        padding-left: 25px !important;
        min-width: 57px !important;
      }

      tbody tr td:nth-child(2),
      thead tr th:nth-child(2) {
        min-width: 150px;
        max-width: 150px;
      }

      tbody tr td:nth-child(3),
      thead tr th:nth-child(3) {
        min-width: 250px;
        max-width: 250px;
      }

      tbody tr td:nth-child(4),
      thead tr th:nth-child(4) {
        min-width: 217px !important;
        width: calc(70vw - (57px / 3) - (2 * 16.8px) + 7px) !important;
        max-width: calc(70vw - (57px / 3) - (2 * 16.8px) + 7px) !important;
      }

      thead tr th {
        background-color: rgba(151, 73, 0, 0.05) !important;
      }

      thead tr th div {
        text-transform: uppercase;
        font-size: 12px;
      }

      .d-action-reasons {
        button {
          width: 32px;
          height: 28px;
          padding: 0;

          svg circle {
            color: #974900 !important;
          }
        }

        .dropdown-item {
          &:hover {
            background-color: #FBEEE8;

            svg path {
              fill: #974900 !important;
            }

            span {
              color: #974900 !important;
            }
          }
        }

        button:hover {
          background-color: #FFDBC4 !important;

        }

        svg {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>